import {MAX_Z_INDEX} from '../constants';

export const MODAL_HEADER_CLASS = 'sda-modal-header';
export const MODAL_HEADERLESS_CLASS = 'sda-modal-headerless';

const SMALL_SCREEN_THRESHOLD = 448;
export const SMALL_SCREEN_WIDTH = 400;
export const MEDIUM_SCREEN_WIDTH = 1000;
export const MARGIN = 16;

/** Number of pixels the background content should shift for Corner modal style */
export const CORNER_CONTENT_SHIFT = 70;

/**
 * We need at least 340px to fit the unified login component,
 * otherwise it will be cut off due to the added margins.
 *
 * This supports up to 375px width viewport.
 */
const UNIFIED_LOGIN_COMPONENT_WIDTH = 340;

const SHEET_MODAL_STLYE = `
  bottom: 0;
  top: auto !important;
  left: 0 !important;
  right: 0 !important;
  border-radius: 20px 20px 0 0;
`;

export const SHEET_MODAL_COMPACT_HTML = `
<style>
.sda-overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: ${MAX_Z_INDEX} !important;
  will-change: opacity, transform;
}

.sda-modal {
  background-color: #fff;
  min-width: ${UNIFIED_LOGIN_COMPONENT_WIDTH}px;
  border-radius: 20px;
  padding: 0;
  will-change: opacity, transform, overflow;
}

.sda-modal:focus {
  outline: 0;
}

.sda-modal.disable-popup {
  ${SHEET_MODAL_STLYE}
}

.${MODAL_HEADER_CLASS} {
  padding: 16px 16px 8px;
  display: flex;
  align-items: center;
}

.${MODAL_HEADER_CLASS} shop-logo {
  flex: 1 0 1px;
  display: flex;
  line-height: 18px;
}

.sda-modal-close-button {
    border: none;
    border-radius: 50%;
    position: relative;
    padding: 0;
    cursor: pointer;
    display: flex;
}

.sda-modal-close-button:focus,
.sda-modal-close-button:hover {
  color: #E3E3E3;
  outline: none;

  --icon-color: #E3E3E3;
}

.sda-modal-close-button:before {
  position: absolute;
  content: '';
  top: 1px;
  left: 1px;
  right: 1px;
  bottom: 1px;
  border-radius: 50%;
  background-color: #707070;
}
.sda-modal-close-button shop-close-icon {
    pointer-events: none;
    z-index: 1;
}

.${MODAL_HEADER_CLASS}.${MODAL_HEADERLESS_CLASS} {
  display: block;
  padding: 0;
  height: 0;
}

.${MODAL_HEADER_CLASS}.${MODAL_HEADERLESS_CLASS} shop-logo {
  display: none;
}

.${MODAL_HEADER_CLASS}.${MODAL_HEADERLESS_CLASS} .sda-modal-close-button {
  position: absolute;
  top: 26px;
  right: 16px;
}

.sda-landing {
    width: ${UNIFIED_LOGIN_COMPONENT_WIDTH}px;
    margin: auto;
}

.sda-hidden {
  height: 0 !important;
  border: 0;
  padding: 0;
  margin: 0;
  visibility: hidden;
  overflow: hidden;
}

.focus-trap {
  border: 0;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

@media (forced-colors: active) {
  .sda-modal {
    border: 1px solid;
  }
}

.arrow {
  position: absolute;
  background-color: #fff;
  width: 24px;
  height: 24px;
  transform: rotate(45deg);
  z-index: -1;
}

@media screen and (max-width: ${SMALL_SCREEN_THRESHOLD}px) {
  .sda-landing {
    width: 100%;
  }

  .sda-modal {
    position: absolute;
    ${SHEET_MODAL_STLYE}
    min-width: ${UNIFIED_LOGIN_COMPONENT_WIDTH}px;
    max-width: unset;
    box-sizing: border-box;
  }

  .arrow {
    display: none;
  }
}

@media screen and (min-width: ${SMALL_SCREEN_THRESHOLD + 1}px) {
  .sda-overlay {
    display: flex;
    align-items: center;
    justify-content: center;
  }


  .sda-modal {
    position: absolute;
    bottom: unset !important;
    right: unset !important;
    margin: 0 auto !important;
    max-width: ${SMALL_SCREEN_WIDTH}px;
    box-sizing: border-box;
  }

  .sda-overlay.sda-cornered {
    background: linear-gradient(rgba(0,0,0,0.95) ${CORNER_CONTENT_SHIFT}px, rgba(0,0,0,0.2) 100%);
    box-sizing: border-box;
    box-shadow: inset 0 ${CORNER_CONTENT_SHIFT}px #000;
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
    padding: 25px;
  }

  .sda-overlay.sda-cornered .sda-modal {
    box-shadow: 0 12px 30px rgba(0,0,0,0.2);
  }
}
</style>

<div class="sda-overlay sda-hidden">
  <button type="button" aria-hidden="true" class="focus-trap focus-trap--start"></button>
  <section class="sda-modal" part="modal" role="dialog" aria-modal="true" aria-label="Sign in with Shop" tabindex="-1">
    <div class="${MODAL_HEADER_CLASS}">
      <shop-logo role="img" size="18" color="brand" label="Shop"></shop-logo>
      <button type="button" class="sda-modal-close-button" aria-label="Close">
        <shop-close-icon compact size="24"/>
      </button>
    </div>
    <div class="sda-landing">
      <slot></slot>
    </div>
  </section>
  <button type="button" aria-hidden="true" class="focus-trap focus-trap--end"></button>
</div>
`;
